<template>
  <div class="eyeBiologyTest">
    <h3><PopularScience :text="'角膜内皮镜'" :gjz="'jiaomoneipijing'"></PopularScience></h3>
<!--    <div style="text-align: left;"> <el-button type="info">读取文件</el-button></div>-->


    <ul class="xxxx">
      <li></li>
      <li class="w15">细胞密度</li>
      <li>平均细胞面积</li>
      <li>六边形细胞比例</li>
      <li>变异系数</li>
    </ul>
    <ul class="xxxx">
      <li>OD</li>
      <li class="w15">
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_SM_OD_CellDensity'?'xz':'']" @focus="getgb(postData.CL_RE_SM_OD_CellDensity, 'CL_RE_SM_OD_CellDensity')" @change="zh('CL_RE_SM_OD_CellDensity')" v-model="postData.CL_RE_SM_OD_CellDensity" placeholder="请输入内容"></el-input>
        <span>cells/mm²</span>
      </li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_SM_OD_AveCellArea'?'xz':'']" @focus="getgb(postData.CL_RE_SM_OD_AveCellArea, 'CL_RE_SM_OD_AveCellArea')"  @change="zh('CL_RE_SM_OD_AveCellArea')" v-model="postData.CL_RE_SM_OD_AveCellArea" placeholder="请输入内容"></el-input>
        <span>um²</span>
      </li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_SM_OD_HCA'?'xz':'']" @focus="getgb(postData.CL_RE_SM_OD_HCA, 'CL_RE_SM_OD_HCA')"  @change="zh('CL_RE_SM_OD_HCA')" v-model="postData.CL_RE_SM_OD_HCA" placeholder="请输入内容"></el-input>
        <span>%</span>
      </li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_SM_OD_CV'?'xz':'']" @focus="getgb(postData.CL_RE_SM_OD_CV, 'CL_RE_SM_OD_CV')"  @change="zh('CL_RE_SM_OD_CV')" v-model="postData.CL_RE_SM_OD_CV" placeholder="请输入内容"></el-input>
        <span>%</span>
      </li>
    </ul>
    <ul class="xxxx">
      <li>OS</li>
      <li class="w15">
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_SM_OS_CellDensity'?'xz':'']" @focus="getgb(postData.CL_RE_SM_OS_CellDensity, 'CL_RE_SM_OS_CellDensity')" @change="zh('CL_RE_SM_OS_CellDensity')" v-model="postData.CL_RE_SM_OS_CellDensity" placeholder="请输入内容"></el-input>
        <span>cells/mm²</span>
      </li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_SM_OS_AveCellArea'?'xz':'']" @focus="getgb(postData.CL_RE_SM_OS_AveCellArea, 'CL_RE_SM_OS_AveCellArea')" @change="zh('CL_RE_SM_OS_AveCellArea')" v-model="postData.CL_RE_SM_OS_AveCellArea" placeholder="请输入内容"></el-input>
        <span>um²</span>
      </li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_SM_OS_HCA'?'xz':'']" @focus="getgb(postData.CL_RE_SM_OS_HCA, 'CL_RE_SM_OS_HCA')" @change="zh('CL_RE_SM_OS_HCA')" v-model="postData.CL_RE_SM_OS_HCA" placeholder="请输入内容"></el-input>
        <span>%</span>
      </li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_SM_OS_CV'?'xz':'']" @focus="getgb(postData.CL_RE_SM_OS_CV, 'CL_RE_SM_OS_CV')" @change="zh('CL_RE_SM_OS_CV')" v-model="postData.CL_RE_SM_OS_CV" placeholder="请输入内容"></el-input>
        <span>%</span>
      </li>
    </ul>

<!--    <div class="tp">-->
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          :auto-upload="false">-->
<!--        <i slot="default" class="el-icon-plus"></i>-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleDownload(file)"-->
<!--        >-->
<!--          <i class="el-icon-download"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--        </div>-->
<!--      </el-upload>-->
<!--      <el-dialog :visible.sync="dialogVisible">-->
<!--        <img width="100%" :src="dialogImageUrl" alt="">-->
<!--      </el-dialog>-->
<!--    </div>-->

    <div class="tp">
      <UPFILE :imgtype="'SM'" :wjtype="'角膜内皮镜'" :datas.sync="contentData"></UPFILE>
<!--      <UPfile :type="'SM'" :imglists="upimglist"></UPfile>-->
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"-->
<!--          :file-list="fileLists"-->
<!--          ref="uptqs"-->
<!--          :on-change="sc"-->
<!--          :auto-upload="false">-->
<!--        &lt;!&ndash;       <i slot="default" class="el-icon-plus"></i>&ndash;&gt;-->
<!--        <div>上传图片</div>-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--          <span-->
<!--              class="el-upload-list__item-preview"-->
<!--              @click="handlePictureCardPreview(file)"-->
<!--          >-->
<!--            <i class="el-icon-zoom-in"></i>-->
<!--          </span>-->
<!--          <span-->
<!--              v-if="!disabled"-->
<!--              class="el-upload-list__item-delete"-->
<!--              @click="handleRemove(file)"-->
<!--          >-->
<!--            <i class="el-icon-delete"></i>-->
<!--          </span>-->
<!--        </span>-->
<!--          <el-dialog :visible.sync="dialogVisible">-->
<!--            <img width="100%" :src="dialogImageUrl" alt="">-->
<!--          </el-dialog>-->
<!--        </div>-->
<!--      </el-upload>-->
    </div>

<!--    <div style="text-align: left;padding-top: 20px;">-->
<!--      <el-select class="xzlex"  v-model="valTypeidx" placeholder="请选择类型">-->
<!--        <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--        </el-option>-->
<!--      </el-select>-->
<!--      <el-button @click="dqwj" type="info">读取文件</el-button>-->
<!--      <el-button @click="tbsbsj">同步设备数据</el-button>-->
<!--    </div>-->

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.CL_RE_SM_Remark">
      </el-input>
    </div>


    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "corneaNPJ",
  data() {
    return {
      contentData: {},
      upimglist: [],
      postData: {
        CL_RE_SM_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        CL_RE_SM_OD_CellDensity: "",
        CL_RE_SM_OS_CellDensity: "",
        CL_RE_SM_OD_AveCellArea: "",
        CL_RE_SM_OS_AveCellArea: "",
        CL_RE_SM_OD_HCA: "",
        CL_RE_SM_OS_HCA: "",
        CL_RE_SM_OD_CV: "",
        CL_RE_SM_OS_CV: ""
      },
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,
      fileLists: [],
      options: [],
      valTypeidx: 1,
      kfid: '',


      showdrsb: false,
      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 0,
      isdrc: 1
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upCorneaNPJ', n)
      },
      deep: true
    },
    contentData: {
      handler(n, o) {
        if (n) {
          for (let key in n) {
            if (n[key]) this.postData[key] = n[key]
          }
        }
        this.$store.commit('eyeTest/upCorneaNPJ', this.postData)
      },
      deep: true
    }
  },
  created() {
    document.addEventListener('click', this.getXY, false)
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getCorneaNPJ()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
            //console.log(this.postData)
          }
        })

    // this._api.publicApi.getImgList('SM')
    //     .then(res => {
    //       let arr = []
    //       if (res.GetListResult && res.GetListResult.length > 0) {
    //         for (let i = 0; i < res.GetListResult.length; i++) {
    //           arr.push({
    //             name: res.GetListResult[i].UFId,
    //             url: res.GetListResult[i].FullURL,
    //           })
    //         }
    //         this.fileLists = arr
    //       }
    //     })


    // this._api.publicApi.getOCRType('角膜内皮镜')
    //     .then(res => {
    //       let arr = []
    //       this.baiduTo = res.BaiduToken
    //       if (res.GetListResult && res.GetListResult.length > 0) {
    //         this.oparr = JSON.parse(JSON.stringify(res.GetListResult))
    //         for (let i = 0; i < res.GetListResult.length; i++) {
    //           arr.push({
    //             value: i + 1,
    //             label: res.GetListResult[i].BDOCR_TempName
    //           })
    //         }
    //       }
    //       this.options = arr
    //     })
  },
  methods: {
    zh(key) {
      this.postData[key] = this.postData[key] !== ''?this.postData[key]:''
    },

    sc(file, f) {
      let _this = this
      let reader = new FileReader(), imgBase64 = '';
      reader.readAsDataURL(file.raw)
      reader.onload = function (e) {
        _this._http.post(pf.getUrl('UploadFile_Upload'), {
          "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
          },
          o: {
            FileBase64: e.currentTarget.result.split(',')[1],
            ExtensionName: ".png",
            UFSaveType: 'OSSPrivate',
            UFReObject: "Choose",
            UFType: 'SM',
            UFReId: _this.$store.state.physicianVisits.xzConsulting
          }
        })
            .then(res => {
              if (res.Id) {
                file.name = res.Id
              }
            })
      }
    },

    handleRemove(file) {
      this._api.publicApi.deleImg(file.name)
          .then(res => {
            if (res.DeleteByIdReturn) {
              let upwj = this.$refs.uptqs.uploadFiles
              for (let i = 0; i < upwj.length; i++) {
                if (upwj[i]['url'] == file.url) {
                  upwj.splice(i, 1)
                }
              }
            }
          })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },

    dqwj() {
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登录请登录！', '提示')
            .then(res => {this.$router.push({path: '/employeesLanding'})})
      }

      if (!this.valTypeidx) {
        return this.$alert('请选择类型！', '提示')
      }


      if (!this.isdrc) return
      this.isdrc = 0
      this.wjsc()

      let str = this.$store.state.physicianVisits.xzConsulting.slice(0,8).toUpperCase()
      let name = this.$store.state.users.CsUser.CustomerName,
          ys = this.$store.state.users.employees.ExpertName,
          temid = this.oparr[this.valTypeidx-1].BDOCR_TempId
      let obj = {
        ChooseNo: str,
        Expert: ys,
        Customer: name,
        CheckType: "角皮内膜镜",
        TempId: temid,
        BDToken: this.baiduTo,
        PageSite: this.oparr[this.valTypeidx-1].BDOCR_PageSite,
        ImageSite: this.oparr[this.valTypeidx-1].BDOCR_ImageSite,
        "OcrId": this.oparr[this.valTypeidx-1].BDOCR_Id,
        "TempName": this.oparr[this.valTypeidx-1].BDOCR_BrandName,
        "CompanyId": this.$store.state.users.user.Company.CompanyId,
        "ShopId": this.$store.state.bases.shop.ShopId,
        "ChooseId": this.$store.state.physicianVisits.xzConsulting,
        "HealthCheckId": '',
        "CustomerId": this.$store.state.users.CsUser.CustomerId,
        "ExpertId": this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000"
      }


      window.bound.selReport(JSON.stringify(obj));
      /*this._api.publicApi.OCRLfirst(this.oparr[this.valTypeidx-1].BDOCR_BrandName, this.oparr[this.valTypeidx-1].BDOCR_Id)
          .then(res => {
            // console.log(res)
            if (res.ReturnCode == 5000) {
              this.kfid = res.Result
              window.bound.selReport(JSON.stringify(obj));
            }else {
              this.$alert(res.displaymsg, '提示')
            }
          })*/

    },
    convertBase64UrlToBlob(urlData){
      let arr = urlData.split(','),
          bstr = window.atob(urlData), n = bstr.length, u8arr = new window.Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new window.Blob([u8arr], {type:'png'});
    },

    wjsc() {
      let _this = this
      window.getReportResult = function (res) {
        // let upwj = _this.$refs.uptqs.uploadFiles
        _this.isdrc = 1
        let obj = JSON.parse(JSON.stringify(_this.postData))
        if (res.BaiduResult) {
          let datas = JSON.parse(res.BaiduResult).data
          if (datas.ret) {
            let obj = {
              CL_RE_SM_ChooseId: _this.postData.CL_RE_SM_ChooseId,
              CL_RE_SM_OD_CellDensity: "",
              CL_RE_SM_OS_CellDensity: "",
              CL_RE_SM_OD_AveCellArea: "",
              CL_RE_SM_OS_AveCellArea: "",
              CL_RE_SM_OD_HCA: "",
              CL_RE_SM_OS_HCA: "",
              CL_RE_SM_OD_CV: "",
              CL_RE_SM_OS_CV: ""
            }
            for (let i = 0; i < datas.ret.length; i++) {
              let str = datas.ret[i].word
              str = str?str.toString():''
              switch (datas.ret[i].word_name) {
                case 'CL_RE_SM_OD_CellDens':
                  obj.CL_RE_SM_OD_CellDensity = str
                  break
                case 'CL_RE_SM_OS_CellDens':
                  obj. CL_RE_SM_OS_CellDensity = str
                  break
                case 'CL_RE_SM_OD_AveCellA':
                  obj.CL_RE_SM_OD_AveCellArea = str
                  break
                case 'CL_RE_SM_OS_AveCellA':
                  obj.CL_RE_SM_OS_AveCellArea = str
                  break
                case 'CL_RE_SM_OD_HCA':
                  obj.CL_RE_SM_OD_HCA = str
                  break
                case 'CL_RE_SM_OS_HCA':
                  obj.CL_RE_SM_OS_HCA = str
                  break
                case 'CL_RE_SM_OD_CV':
                  obj.CL_RE_SM_OD_CV = str
                  break
                case 'CL_RE_SM_OS_CV':
                  obj.CL_RE_SM_OS_CV = str
                  break
              }
            }
            for (let key in obj) {
              if (obj[key]) {
                _this.postData[key] = obj[key]
                _this.$set(_this.postData, key, obj[key])
              }
            }

            _this.$store.commit('eyeTest/upCorneaNPJ', _this.postData)

            // _this.postData = obj
            // _this._api.publicApi.OCRsecond(_this.kfid, true)
            //     .then(res => {
            //       console.log(res)
            //     })
          }
        }

        // console.log(res)

        if (res.ReportImages && res.ReportImages.length) {

          for (let i = 0; i < res.ReportImages.length; i++) {

            _this._api.publicApi.upFiles(res.ReportImages[i].ImageBase64, 'SM')
                .then(res => {
                  _this.upimglist.push(res)
                })
            /*let formData = new window.FormData() ;
            let blobDate = _this.convertBase64UrlToBlob( res.ReportImages[i].ImageBase64 );
            let url = window.URL.createObjectURL(blobDate)
            let stri = pf.randomString(6)+".png"
            formData.append(stri, blobDate); // 文件对象
            _this._http.post(pf.getUrl('UploadFile_Upload'), {
              "token": {
                "TimeSpan": 1566827733,
                "sToken": "768DCAFFF869CD61372790C2738012EC"
              },
              o: {
                FileBase64:  res.ReportImages[i].ImageBase64,
                ExtensionName: "."+formData.get(stri).type,
                UFSaveType: 'OSSPrivate',
                UFReObject: "Choose",
                UFType: 'SM',
                UFReId: _this.$store.state.physicianVisits.xzConsulting
              }
            })
                .then(r => {
                  if (r.Id) {
                    upwj.push({
                      name: r.Id,
                      raw: formData.get(stri),
                      status: "ready",
                      percentage: 0,
                      size: formData.get(stri).size,
                      uid: pf.randomString(18),
                      url
                    })
                  }
                })*/
          }
        }
        if (res.ReportPages && res.ReportPages.length) {

          for (let i = 0; i < res.ReportPages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportPages[i].PageBase64, 'SM')
                .then(res => {
                  _this.upimglist.push(res)
                })
            /*let formData = new window.FormData() ;
            let blobDate = _this.convertBase64UrlToBlob( res.ReportPages[i].PageBase64 );
            let url = window.URL.createObjectURL(blobDate)
            let stri = pf.randomString(6)+".png"
            formData.append(stri, blobDate); // 文件对象
            _this._http.post(pf.getUrl('UploadFile_Upload'), {
              "token": {
                "TimeSpan": 1566827733,
                "sToken": "768DCAFFF869CD61372790C2738012EC"
              },
              o: {
                FileBase64:  res.ReportPages[i].PageBase64,
                ExtensionName: "."+formData.get(stri).type,
                UFSaveType: 'OSSPrivate',
                UFReObject: "choose",
                UFType: 'SM',
                UFReId: _this.$store.state.physicianVisits.xzConsulting
              }
            })
                .then(r => {
                  if (r.Id) {
                    upwj.push({
                      name: r.Id,
                      raw: formData.get(stri),
                      status: "ready",
                      percentage: 0,
                      size: formData.get(stri).size,
                      uid: pf.randomString(18),
                      url
                    })
                  }
                })*/
          }
        }


        console.log(_this.upimglist)
      }
    },

    tbsbsj() {
      const _this = this
      window.readFileResult = function(res) {
        if (res) {
          let datas = typeof res === 'object'?res:JSON.parse(res)
          if (datas.Msg === 'Success') {

            let obj = {
              CL_RE_SM_ChooseId: _this.postData.CL_RE_SM_ChooseId,
              CL_RE_SM_OD_CellDensity: "",
              CL_RE_SM_OS_CellDensity: "",
              CL_RE_SM_OD_AveCellArea: "",
              CL_RE_SM_OS_AveCellArea: "",
              CL_RE_SM_OD_HCA: "",
              CL_RE_SM_OS_HCA: "",
              CL_RE_SM_OD_CV: "",
              CL_RE_SM_OS_CV: ""
            }

            obj.CL_RE_SM_OD_CellDensity = datas.ReadResult.RDensity?datas.ReadResult.RDensity.toString():''
            obj.CL_RE_SM_OS_CellDensity = datas.ReadResult.LDensity?datas.ReadResult.LDensity.toString():''

            obj.CL_RE_SM_OD_AveCellArea = datas.ReadResult.RAreaAvg?datas.ReadResult.RAreaAvg.toString():''
            obj.CL_RE_SM_OS_AveCellArea = datas.ReadResult.LAreaAvg?datas.ReadResult.LAreaAvg.toString():''

            obj.CL_RE_SM_OD_HCA = datas.ReadResult.REx?datas.ReadResult.REx.toString():''
            obj.CL_RE_SM_OS_HCA = datas.ReadResult.LEx?datas.ReadResult.LEx.toString():''

            obj.CL_RE_SM_OD_CV = datas.ReadResult.RCV?datas.ReadResult.RCV.toString():''
            obj.CL_RE_SM_OS_CV = datas.ReadResult.LCV?datas.ReadResult.LCV.toString():''

            _this.postData = JSON.parse(JSON.stringify(obj))

            let upwj = _this.$refs.uptqs.uploadFiles
            if (res.ReadImageList && res.ReadImageList.length) {
              for (let i = 0; i < res.ReadImageList.length; i++) {
                let formData = new window.FormData() ;
                let blobDate = _this.convertBase64UrlToBlob( res.ReadImageList[i]);
                let url = window.URL.createObjectURL(blobDate)
                let stri = pf.randomString(6)+".png"
                formData.append(stri, blobDate); // 文件对象
                _this._http.post(pf.getUrl('UploadFile_Upload'), {
                  "token": {
                    "TimeSpan": 1566827733,
                    "sToken": "768DCAFFF869CD61372790C2738012EC"
                  },
                  o: {
                    FileBase64: res.ReadImageList[i],
                    ExtensionName: "."+formData.get(stri).type,
                    UFSaveType: 'OSSPrivate',
                    UFReObject: "choose",
                    UFType: 'SM',
                    UFReId: _this.$store.state.physicianVisits.xzConsulting
                  }
                })
                        .then(r => {
                          if (r.Id) {
                            upwj.push({
                              name: r.Id,
                              raw: formData.get(stri),
                              status: "ready",
                              percentage: 0,
                              size: formData.get(stri).size,
                              uid: pf.randomString(18),
                              url
                            })
                          }
                        })
              }
            }

          }else {
            _this.$alert('数据获取失败！','提示')
          }
        }
      }

      window.bound.readFile("角膜内皮镜")
    },

    keyd(e) {
      // e.returnValue= false
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf) {
      // console.log(e)
      // document.activeElement.blur();
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData, this.srval, e)
      if (this.postData[this.srval] && !isNaN(Number(this.postData[this.srval])) && this.iszf) {
        if (this.postData[this.srval] && Number(this.postData[this.srval]) > 0 && this.postData[this.srval].indexOf('+') < 0  && this.postData[this.srval].indexOf('-') < 0) {
          this.$set(this.postData, this.srval, '+'+e)
        }
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  },
  components: {
    KEYS: () => import('@/components/keyboard/keyHome'),
    UPfile: () => import('@/components/CommonComponents/upFile'),
    UPFILE: () => import('../../components/sbData')
  }
}
</script>

<style scoped lang="scss">
.eyeBiologyTest {
  width: 90%;
  margin: 0 auto;
}
h3{padding-bottom: 0.3rem}
.xxxx {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 20px;
  li {
    width: 14vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li:nth-child(1) {
    justify-content: start;
    width: 5%;
  }
  .w15 {width: 15vw;}
  .pj {margin: 0.03rem 0.2rem; margin-right: 0.05rem;}
}
::v-deep  .xxxx li input:hover{background: #e8e6e6}
::v-deep .xz input {background: #e8e6e6}
.tp {
  display: flex;
  justify-content: start;
  padding-top: 0.4rem;
}
.bz {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 30px;
}
.bz p {padding-bottom: 10px;font-size: 17px}
.xzlex {
  width: 200px;
  margin-right: 2vw;
}
</style>
